import { Box, CircularProgress } from '@mui/material';
import { primaryLight } from 'common/constants/colors';
import { selectGlobalFilters } from 'common/store/selectors';
import { Styles } from 'common/types';
import { publisherGroups } from 'common/constants/publisher-groups';
import { asinRecommendationActions } from 'modules/asin-recommendation/store/actions/actions';
import { asinRecommendationSelectors } from 'modules/asin-recommendation/store/selectors';
import { selectCombined } from 'modules/overview-v2/store/selectors';
import { selectPublisherInfo } from 'modules/settings/store/selectors';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import { FiltersModal } from '../filters-modal';
import { FeaturedList, ProductList } from '../product-list';
import { RecommendModal } from '../recommend-modal';

const styles: Styles = {
  featuredContainer: { bgcolor: primaryLight, borderRadius: 1, p: 2, mt: 1 },
  featuredLoading: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    bgcolor: primaryLight,
    borderRadius: 3,
    p: 2,
    mt: 1,
    height: 475,
  },
  otherContainer: { p: 2, mt: 3 },
  otherLoading: { display: 'flex', justifyContent: 'center', alignItems: 'center', p: 2, mt: 3 },
};

interface Props {
  filtersModalOpen: boolean;
  handleFiltersClose: () => void;
  getAsinParams: AsinRecommendationModule.Params.GetAsinsParams;
  view: string;
}

export function RecommendedGrid({ filtersModalOpen, handleFiltersClose, getAsinParams, view }: Props): ReactElement {
  const dispatch = useDispatch();

  const [featuredOn, setFeaturedOn] = useState<boolean>(true);

  const publisher = useSelector(selectPublisherInfo);
  const filters = useSelector(asinRecommendationSelectors.selectFilters);
  const table = useSelector(asinRecommendationSelectors.selectTable);
  const grid = useSelector(asinRecommendationSelectors.selectGrid);
  const recommended = useSelector(asinRecommendationSelectors.selectAsins);
  const featured = useSelector(asinRecommendationSelectors.selectFeaturedAsins);
  const { isCombined } = useSelector(selectCombined);
  const globalFilters = useSelector(selectGlobalFilters);

  const getRecommendedAsinsParams = useMemo<AsinRecommendationModule.Params.GetAsinsParams>(
    () => ({
      ...getAsinParams,
      product_sort: table.product_sort,
      price_sort: table.price_sort,
      limit: table.limit,
      offset: table.offset,
    }),
    [getAsinParams, table]
  );

  const getFeaturedAsinsParams = useMemo<AsinRecommendationModule.Params.GetAsinsParams>(
    () => ({
      ...getRecommendedAsinsParams,
      limit: grid.featuredLimit,
      offset: grid.featuredOffset,
      search: '',
      featured: true,
    }),
    [getRecommendedAsinsParams, grid]
  );

  const getPlacementsParams = useMemo<AsinRecommendationModule.Params.GetPlacementParams>(
    () => ({
      publisher_id: Number(publisher?.id),
      asin: grid.selected?.asin,
      title: grid.selected?.extended_product.title,
      ...(isCombined ? { publisher_group: Number(publisherGroups[globalFilters.publisher_group]) } : {}),
    }),
    [grid.selected, publisher, globalFilters.publisher_group, isCombined]
  );

  const getVariationsParams = useMemo<AsinRecommendationModule.Params.GetVariationsParams>(
    () => ({
      publisher_id: Number(publisher?.id),
      parent_id: grid.selected?.pp_product_id,
    }),
    [grid.selected, publisher]
  );

  const handleApply = () => {
    dispatch(
      asinRecommendationActions.asins.getAsins({
        ...getRecommendedAsinsParams,
        avg_review_max: filters.rating_max,
        avg_review_min: filters.rating_min,
        discount_max: filters.discount_max,
        discount_min: filters.discount_min,
        offset: 0,
      })
    );
    if (
      filters.categories.length === 0 &&
      filters.brand.length === 0 &&
      filters.type === 'all' &&
      filters.status === 'all' &&
      filters.seasons === 'none' &&
      filters.in_stock === 'all' &&
      !filters.price_min &&
      !filters.price_max &&
      !filters.earn_min &&
      !filters.earn_max
    ) {
      setFeaturedOn(true);
      dispatch(asinRecommendationActions.featuredAsins.getFeaturedAsins(getFeaturedAsinsParams));
    } else {
      setFeaturedOn(false);
    }
    handleFiltersClose();
  };

  const handleReset = () => {
    dispatch(asinRecommendationActions.filters.resetFilters());
  };

  const handleGridModalClose = () => {
    dispatch(asinRecommendationActions.grid.setSelected(null));
    dispatch(asinRecommendationActions.grid.setGridModalOpen(false));
    dispatch(asinRecommendationActions.placements.getPlacementsSuccess(null));
    dispatch(asinRecommendationActions.variations.getVariationsSuccess(null));
  };

  const debouncedRecommendedAsins = useDebouncedCallback((params: AsinRecommendationModule.Params.GetAsinsParams) => {
    dispatch(asinRecommendationActions.asins.getAsins(params));
    if (filters.search !== '') {
      setFeaturedOn(false);
    } else {
      setFeaturedOn(true);
      dispatch(asinRecommendationActions.featuredAsins.getFeaturedAsins(getFeaturedAsinsParams));
    }
  }, 300);

  useEffect(() => {
    if (view === 'grid') {
      debouncedRecommendedAsins(getRecommendedAsinsParams);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.discount_products_only, filters.search, view]);

  useEffect(() => {
    if (view === 'grid') {
      dispatch(asinRecommendationActions.asins.getAsins(getRecommendedAsinsParams));
      dispatch(asinRecommendationActions.featuredAsins.getFeaturedAsins(getFeaturedAsinsParams));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view]);

  useEffect(() => {
    if (grid.selected) {
      dispatch(asinRecommendationActions.grid.setGridModalOpen(true));
      if (grid.selected?.in_stock && grid.selected?.asin_status === 'Not In Article')
        dispatch(asinRecommendationActions.placements.getPlacements(getPlacementsParams));
      if (grid.selected?.variations) dispatch(asinRecommendationActions.variations.getVariations(getVariationsParams));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grid.selected]);

  return (
    <>
      {publisher?.publisher_categories.length > 0 && featuredOn && !isCombined && (
        <Box sx={styles.featuredContainer}>
          {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
          <>
            {featured?.loading ? (
              <Box sx={styles.featuredLoading}>
                <CircularProgress />
              </Box>
            ) : (
              <FeaturedList data={featured} asinParams={getFeaturedAsinsParams} />
            )}
          </>
        </Box>
      )}
      <Box sx={styles.otherContainer}>
        {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
        <>
          {recommended?.loading ? (
            <Box sx={styles.otherLoading}>
              <CircularProgress />
            </Box>
          ) : (
            <ProductList data={recommended} asinParams={getRecommendedAsinsParams} />
          )}
        </>
      </Box>
      <RecommendModal open={grid.modalOpen} onClose={handleGridModalClose} />
      <FiltersModal
        onClose={handleFiltersClose}
        isOpen={filtersModalOpen}
        handleApply={handleApply}
        handleReset={handleReset}
        type="grid"
      />
    </>
  );
}
