import { Action } from 'common/interfaces/root';
import { BrandsState } from 'common/interfaces/brands';
import { brandsActionTypes as TYPES } from '../actions/brands';

const initState: BrandsState = {
  data: null,
  loading: false,
};

export const brandsReducer = (state: BrandsState = initState, { type, payload }: Action): BrandsState => {
  switch (type) {
    case TYPES.GET_BRANDS:
      return { ...state, loading: true };
    case TYPES.GET_BRANDS_SUCCESS:
      return { ...state, loading: false, data: payload };
    case TYPES.GET_BRANDS_ERROR:
      return { ...state, loading: false };

    default:
      return state;
  }
};
