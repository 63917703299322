import { State } from 'common/store/reducers';

const selectAsins = (state: State) => state.asinRecommendation.asins;
const selectFeaturedAsins = (state: State) => state.asinRecommendation.featuredAsins;
const selectPlacements = (state: State) => state.asinRecommendation.placements;
const selectExports = (state: State) => state.asinRecommendation.exports;
const selectFilters = (state: State) => state.asinRecommendation.filters;
const selectTable = (state: State) => state.asinRecommendation.table;
const selectGrid = (state: State) => state.asinRecommendation.grid;
const selectVariations = (state: State) => state.asinRecommendation.variations;
const selectInitialFilters = (state: State) => state.asinRecommendation.initialFilters;

export const asinRecommendationSelectors = {
  selectAsins,
  selectFeaturedAsins,
  selectPlacements,
  selectExports,
  selectFilters,
  selectTable,
  selectGrid,
  selectVariations,
  selectInitialFilters,
};
