import { combineReducers } from 'redux';
import exports from './export';
import upload from './upload';
import filters from './filters';
import form from './form';
import conversionsBoost from './conversions-boost';

export const asinsConversionsBoostReducers = combineReducers({
  exports,
  upload,
  filters,
  form,
  conversionsBoost,
});
