/* eslint-disable react-hooks/exhaustive-deps */
import { Autocomplete, Box, Button, Chip, TextField } from '@mui/material';
import { brandsActions } from 'common/store/actions/brands';
import { selectBrands, selectBrandsLoading } from 'common/store/selectors';
import { Styles } from 'common/types';
import { Modal } from 'common/ui/modal';
import { publishersSettingsActions } from 'modules/publishers-settings/store/actions';
import { selectPublisherBlockedBrands } from 'modules/publishers-settings/store/selectors';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

const styles: Styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '20px',
  },
  button: {
    maxWidth: 100,
    width: '100%',
    marginLeft: 'auto',
    ':first-child': { marginLeft: 0 },
  },
};

interface Props {
  publisherID: number;
  open: boolean;
  onClose: () => void;
  onSuccess?: () => void;
}

export function BlockBrandsModal({ publisherID, open, onSuccess, onClose }: Props) {
  const dispatch = useDispatch();
  const blockedBrands = useSelector(selectPublisherBlockedBrands);
  const [publisherBrandsToModify, setPublisherBrandsToModify] = useState([]);
  const brands = useSelector(selectBrands);
  const brandsLoading = useSelector(selectBrandsLoading);

  const handleSubmit = () => {
    const unblockBrands = blockedBrands.filter(b => !publisherBrandsToModify.find(p => p.id === b.brand_id));
    const blockBrands = publisherBrandsToModify.filter(p => !blockedBrands.find(b => b.brand_id === p.id));

    if (unblockBrands?.length) {
      dispatch(
        publishersSettingsActions.unblockPublisherBrand({
          publisher_id: publisherID,
          brand_ids: unblockBrands.map(b => b.brand_id),
        })
      );
    }

    if (blockBrands?.length) {
      dispatch(
        publishersSettingsActions.blockPublisherBrand({
          publisher_id: publisherID,
          brand_ids: blockBrands.map(b => b.id),
        })
      );
    }

    onSuccess();
  };

  useEffect(() => {
    if (!brands?.length) {
      dispatch(brandsActions.getBrands());
    }
    dispatch(publishersSettingsActions.getPublisherBlockedBrands(publisherID));
  }, []);

  useEffect(() => {
    setPublisherBrandsToModify(blockedBrands.map(b => ({ label: b.brand_name, id: b.brand_id })));
  }, [blockedBrands]);

  return (
    <Modal title="Block Brands" open={open} onClose={onClose}>
      <Box sx={styles.container}>
        <Autocomplete
          multiple
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip variant="outlined" size="small" label={option.label} {...getTagProps({ index })} />
            ))
          }
          renderInput={params => (
            <TextField {...params} variant="outlined" label="Brands" size="small" placeholder="Brand" />
          )}
          options={brands?.map(b => ({ label: b.name, id: b.id })) || []}
          isOptionEqualToValue={(option, value) => option?.id === value?.id}
          onChange={(_e, v) => setPublisherBrandsToModify(v as { label: string; id: number }[])}
          value={publisherBrandsToModify}
          loading={brandsLoading}
        />
        <Button color="primary" onClick={handleSubmit} sx={styles.button} variant="contained">
          Submit
        </Button>
      </Box>
    </Modal>
  );
}
