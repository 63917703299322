import { Action } from 'common/interfaces/root';
import { asinRecommendationActionTypes as T } from '../actions/action-types';

const initialState: AsinRecommendationModule.State.initialFiltersState = {
  parent_categories: [],
  brands: [],
  categories: [],
  discount_filters: {
    min: 0,
    max: 100,
  },
  rating_filters: {
    min: 1,
    max: 5,
  },
  types: [],
  loading: false,
  error: null,
};

export default (
  state: AsinRecommendationModule.State.initialFiltersState = initialState,
  { type, payload }: Action
): AsinRecommendationModule.State.initialFiltersState => {
  switch (type) {
    case T.INITIAL_FILTERS.GET_FILTERS:
      return { ...initialState, loading: true };
    case T.INITIAL_FILTERS.GET_FILTERS_SUCCESS:
      return { ...state, loading: false, ...payload };
    case T.INITIAL_FILTERS.GET_FILTERS_ERROR:
      return { ...state, loading: false, error: payload };
    default:
      return state;
  }
};
