import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectPublishers } from 'modules/publishers-settings/store/selectors';
import { Box, Button } from '@mui/material';
import { Styles } from 'common/types';
import { Banner } from 'modules/parent-publisher-settings/components/banner';
import { brandsActions } from 'common/store/actions/brands';
import { publisherBrandCommissionsActions } from './store/actions/actions';
import {
  selectPublisherBrandCommissions,
  selectPublisherBrandCommissionsError,
  selectPublisherBrandCommissionsLoading,
} from './store/selectors';
import { BrandsCommissionsTable } from './components/brands-commissions-table/brands-commissions-table';
import {
  AddBrandCommissionModal,
  type ModalFormState,
} from './components/add-brand-commission-modal/add-brand-commission-modal';
import { PublisherSearch } from './components/publishers-search/publishers-search';

const styles: Styles = {
  filtersContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '20px 0',
  },
  button: {
    width: '100%',
    maxWidth: '200px',
    boxShadow: 'none',
  },
};

export function PublisherBrandCommissionsSettings() {
  const dispatch = useDispatch();
  const publishers = useSelector(selectPublishers);
  const brandCommissions = useSelector(selectPublisherBrandCommissions);
  const loading = useSelector(selectPublisherBrandCommissionsLoading);
  const error = useSelector(selectPublisherBrandCommissionsError);
  const [selectedPublisher, setSelectedPublisher] = useState(0);
  const [showAddBrandCommissionModal, setShowAddBrandCommissionModal] = useState(false);
  const [actionType, setActionType] = useState<'add' | 'edit'>('add');
  const [formInitialState, setFormInitialState] = useState<ModalFormState>({ brand_id: '', commission_rate: '' });
  const handlePublisherSelect = (publisherId: number) => {
    setSelectedPublisher(publisherId);
  };

  const handleAddBrandCommissionButtonClick = () => {
    setActionType('add');
    setFormInitialState({
      brand_id: '',
      commission_rate: '',
    });
    setShowAddBrandCommissionModal(true);
  };

  const handleEditClick = ({ brand_id, commission_rate }) => {
    setActionType('edit');
    setFormInitialState({
      brand_id,
      commission_rate,
    });
    setShowAddBrandCommissionModal(true);
  };

  const handleDeleteClick = (brand_id: number) => {
    dispatch(
      publisherBrandCommissionsActions.deletePublisherBrandCommission({
        publisher_id: selectedPublisher,
        brand_id,
      })
    );
  };

  const handleCloseModal = () => {
    setShowAddBrandCommissionModal(false);
  };

  const handleFormSubmit = (formData: ModalFormState) => {
    switch (actionType) {
      case 'add': {
        dispatch(
          publisherBrandCommissionsActions.addPublisherBrandCommission({
            publisher_id: selectedPublisher,
            brand_id: +formData.brand_id,
            commission_rate: +formData.commission_rate,
          })
        );
        break;
      }
      case 'edit': {
        dispatch(
          publisherBrandCommissionsActions.editPublisherBrandCommission({
            publisher_id: selectedPublisher,
            brand_id: +formData.brand_id,
            commission_rate: +formData.commission_rate,
          })
        );
        break;
      }
      default: {
        throw new Error('Unknown action');
      }
    }
    setShowAddBrandCommissionModal(false);
  };

  useEffect(() => {
    if (selectedPublisher) {
      dispatch(publisherBrandCommissionsActions.getPublisherBrandCommissions(selectedPublisher));
    }
    //  eslint-disable-next-line
  }, [selectedPublisher]);

  useEffect(() => {
    dispatch(brandsActions.getBrands());
    //  eslint-disable-next-line
  }, []);

  return (
    <>
      <Banner title="Publishers Brand Commissions" />
      <PublisherSearch
        publishers={publishers}
        onSelect={handlePublisherSelect}
        selectedPublisherName={publishers.find(p => p.id === selectedPublisher)?.name || ''}
      />
      {selectedPublisher !== 0 && !loading && !error && (
        <Box sx={styles.filtersContainer}>
          <Button variant="contained" sx={styles.button} onClick={handleAddBrandCommissionButtonClick}>
            Add brand
          </Button>
        </Box>
      )}
      <BrandsCommissionsTable
        data={brandCommissions}
        loading={loading}
        onDeleteIconClick={handleDeleteClick}
        onEditIconClick={handleEditClick}
      />
      <AddBrandCommissionModal
        isShowModal={showAddBrandCommissionModal}
        onClose={handleCloseModal}
        onFormSubmit={handleFormSubmit}
        onCancelButtonClick={() => setShowAddBrandCommissionModal(false)}
        actionType={actionType}
        initialState={formInitialState}
      />
    </>
  );
}
