import { ApiService } from 'common/services';

class AsinRecommendationService extends ApiService {
  getAsins = (params: AsinRecommendationModule.Params.GetAsinsParams) => {
    return this.get(`/api/affiliate/recommended-asins/${params.publisher_id}`, { params });
  };

  getPlacements = (params: AsinRecommendationModule.Params.GetPlacementParams) => {
    return this.get('/api/affiliate/recommended-asins/placements', { params });
  };

  getAsinRecommendationExport = (params: AsinRecommendationModule.Params.GetAsinsParams) => {
    return this.get(`/api/affiliate/recommended-asins/export/v2/${params.publisher_id}`, { params });
  };

  getVariations = (params: AsinRecommendationModule.Params.GetVariationsParams) => {
    return this.get('/api/affiliate/recommended-asin/variations', { params });
  };

  generateTag = (publisherId: number, asin: string, url: string) => {
    return this.post('/api/amazon-attribution/tags/generate', { publisher_id: publisherId, asin, url });
  };

  getExportStatus = (id: string) => {
    return this.get(`/api/export/${id}/status`);
  };

  getInitialFilters = params => {
    return this.get('/api/affiliate//recommended-asins/filters', {
      params,
    });
  };
}

export const asinRecommendationService = new AsinRecommendationService(process.env.REACT_APP_API_URL);
