import { Box, Button, MenuItem, TextField, Typography } from '@mui/material';
import { Styles } from 'common/types';
import { Modal } from 'common/ui/modal';
import { ChangeEvent, ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { recommendedAsinsActions } from 'modules/affiliate-gainz/store/recommended-asins/actions';
import { selectCategories } from 'modules/affiliate-gainz/store/selectors';

const styles: Styles = {
  container: { display: 'flex', flexDirection: 'column', gap: 1.5 },
  fieldContainer: { display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
  minMaxContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '70%',
  },
  field: { width: '70%' },
  btnContainer: { display: 'flex', justifyContent: 'flex-end', gap: 1.5 },
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
  handleApply: () => void;
  handleReset: () => void;
  filters: AffiliateGainzModule.RecommendedAsinsModule.FiltersState;
}

export function FiltersModal({ isOpen, onClose, handleApply, handleReset, filters }: Props): ReactElement {
  const categories = useSelector(selectCategories);

  const dispatch = useDispatch();

  const handleCategoryChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const categoryValues = e.target.value.split(',');
    dispatch(recommendedAsinsActions.setCategory(categoryValues));
  };

  const handleStatusChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(recommendedAsinsActions.setStatus(e.target.value));
  };

  const handleSeasonChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(recommendedAsinsActions.setSeason(e.target.value));
  };

  const handlePriceMinChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    dispatch(recommendedAsinsActions.setPriceMin(e.target.value));
  };

  const handlePriceMaxChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    dispatch(recommendedAsinsActions.setPriceMax(e.target.value));
  };

  const handleEarnMinChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    dispatch(recommendedAsinsActions.setEarnMin(e.target.value));
  };

  const handleEarnMaxChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    dispatch(recommendedAsinsActions.setEarnMax(e.target.value));
  };

  return (
    <Modal open={isOpen} onClose={onClose} title="Filters" maxWidth="sm">
      <Box sx={styles.container}>
        <Box sx={styles.fieldContainer}>
          <Typography sx={styles.title}>Category</Typography>
          <TextField
            select
            color="primary"
            sx={styles.field}
            value={filters.category}
            size="small"
            label="Categories"
            onChange={handleCategoryChange}
          >
            <MenuItem value="all">All</MenuItem>
            {categories.data?.map(category => (
              <MenuItem key={category.parent_category.name} value={category.parent_category.name}>
                {category.parent_category.name}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box sx={styles.fieldContainer}>
          <Typography sx={styles.title}>Status</Typography>
          <TextField
            select
            color="primary"
            sx={styles.field}
            value={filters.status}
            size="small"
            label="Status"
            onChange={handleStatusChange}
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="active">In article</MenuItem>
            <MenuItem value="inactive">Not in article</MenuItem>
          </TextField>
        </Box>
        <Box sx={styles.fieldContainer}>
          <Typography sx={styles.title}>Seasonality</Typography>
          <TextField
            select
            color="primary"
            sx={styles.field}
            value={filters.season}
            size="small"
            label="Seasonality"
            onChange={handleSeasonChange}
          >
            <MenuItem value="none">None</MenuItem>
            <MenuItem value="current_month">Current Month</MenuItem>
            <MenuItem value="next_month">Next Month</MenuItem>
            <MenuItem value="next_three_months">Next Three Months</MenuItem>
            <MenuItem value="next_six_months">Next Six Months</MenuItem>
          </TextField>
        </Box>
        <Box sx={styles.fieldContainer}>
          <Typography sx={styles.title}>Product Price</Typography>
          <Box sx={styles.minMaxContainer}>
            <TextField
              label="Product price min"
              type="number"
              size="small"
              value={filters.price_min || 0}
              InputLabelProps={{ shrink: true }}
              onChange={handlePriceMinChange}
            />
            <TextField
              label="Product price max"
              type="number"
              size="small"
              value={filters.price_max || 0}
              InputLabelProps={{ shrink: true }}
              onChange={handlePriceMaxChange}
            />
          </Box>
        </Box>
        <Box sx={styles.fieldContainer}>
          <Typography sx={styles.title}>Opportunity Earn %</Typography>
          <Box sx={styles.minMaxContainer}>
            <TextField
              label="Opportunity earn min %"
              type="number"
              size="small"
              value={filters.earn_min || 0}
              InputLabelProps={{ shrink: true }}
              onChange={handleEarnMinChange}
            />
            <TextField
              label="Opportunity earn max %"
              type="number"
              size="small"
              value={filters.earn_max || 0}
              InputLabelProps={{ shrink: true }}
              onChange={handleEarnMaxChange}
            />
          </Box>
        </Box>
        <Box sx={styles.btnContainer}>
          <Button sx={styles.btn} variant="outlined" onClick={handleReset}>
            Reset
          </Button>
          <Button sx={styles.btn} variant="contained" onClick={handleApply}>
            Apply
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
