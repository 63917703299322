import { ChangeEvent, ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { useFormikContext } from 'formik';
import { Styles } from 'common/types';
import { Autocomplete, Box, Button, Chip, OutlinedInput, TextField, Typography } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { theme } from 'common/constants/theme';
import { Field } from 'common/ui/field';
import { SelectField } from 'common/ui/select-field';
import { selectPublisherGroups, selectPublisherInfo } from 'modules/settings/store/selectors';
import { publishersSettingsActions } from 'modules/settings/store/actions';
import { usePermission } from 'common/hooks';
import { PERMISSIONS } from 'common/constants/permissions';
import { selectPublishersSettingsFilters } from 'modules/publishers-settings/store/selectors';
import { recommendedAsinsActions } from 'modules/affiliate-gainz/store/recommended-asins/actions';
import { selectCategories } from 'modules/affiliate-gainz/store/selectors';
import { selectRootDemo } from '../../../../../../../../common/store/selectors';

interface StylesProps {
  image: string;
  isDemoMode: boolean;
}

const getStyles = ({ image, isDemoMode }: StylesProps): Styles => ({
  content: {
    backgroundColor: '#ffffff',
    width: '100%',
    borderRadius: '10px',
    boxShadow: '0 2px 5px rgb(0 0 0 / 5%)',
    padding: '0 28px 28px 28px',
    boxSizing: 'border-box',
  },
  title: {
    fontSize: '16px',
    fontWeight: 600,
    width: '100%',
    boxSizing: 'border-box',
    padding: '20px 0 0 0',
    ':first-child': { padding: 0 },
  },
  fieldWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '20px 0',
    boxSizing: 'border-box',
    borderBottom: '1px solid #C2DEEB40',
    ':last-child': { borderBottom: 0 },
  },
  titleField: {
    color: '#214254',
    fontSize: '12px',
  },
  button: {
    width: 100,
    height: 45,
    marginLeft: 1,
    ':first-child': { marginLeft: 0 },
    borderRadius: '10px',
  },
  buttons: { marginTop: 2, display: 'flex', justifyContent: 'space-between', width: '100%' },
  logoButtons: { marginTop: 2, display: 'flex', justifyContent: 'space-between', width: '70%' },
  inputLogoWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minWidth: '405px',
    marginRight: '175px',
    boxSizing: 'border-box',
  },
  inputWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minWidth: '405px',
    marginRight: '175px',
    boxSizing: 'border-box',
    filter: isDemoMode ? 'blur(5px)' : 0,
  },
  avatar: {
    backgroundImage: `url(${image})`,
    borderRadius: '50%',
    backgroundSize: 'cover',
    width: 65,
    height: 65,
    backgroundColor: theme.palette.primary.light,
  },
  field: { marginTop: 0, width: '100%', maxWidth: '405px' },
  hiddenInput: { display: 'none', filter: isDemoMode ? 'blur(5px)' : 0 },
  groupsContainer: { display: 'flex', justifyContent: 'flex-start', width: '405px', marginRight: '175px' },
  groupContainer: { display: 'flex', alignItems: 'center', marginRight: 2 },
  checkCircleIcon: { marginRight: 1 },
});

export function MainSettingsContent(): ReactElement {
  const dispatch = useDispatch();
  const { submitForm, setFieldValue } = useFormikContext<UserSettingsModule.UserSettingsForm>();
  const formik = useFormikContext<PublishersSettingsModule.MainPublisherSettingsForm>();
  const inputRef = useRef<HTMLInputElement>(null);
  const permissions = usePermission(PERMISSIONS.GET_PUBLISHERS);

  const [isPreUpload, setIsPreUpload] = useState<boolean>(false);
  const [preUpload, setPreUpload] = useState<string>('');

  const publisher = useSelector(selectPublisherInfo);
  const publisherGroups = useSelector(selectPublisherGroups);
  const isDemoMode = useSelector(selectRootDemo);
  const filters = useSelector(selectPublishersSettingsFilters);
  const categories = useSelector(selectCategories);

  const timezones = useMemo(() => {
    return moment.tz.names().map(tz => ({ title: tz, value: tz }));
  }, []);

  const styles = getStyles({ image: isPreUpload ? preUpload : publisher?.logo, isDemoMode });

  const handleAvatarChange = (value: File) => {
    setIsPreUpload(true);
    setFieldValue('logo', value);
  };

  const handleChangeAvatar = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0];
    if (!file) return;

    const fileReader = new FileReader();

    fileReader.onload = () => {
      setPreUpload(fileReader.result as string);
    };

    fileReader.readAsDataURL(file);
    e.target.value = '';
    handleAvatarChange(file);
  };

  const handleDeleteAvatar = () => {
    setPreUpload(null);
    handleAvatarChange(null);
  };

  const handleClickInput = () => {
    if (!inputRef.current) return;
    inputRef.current.click();
  };

  useEffect(() => {
    if (preUpload || preUpload === null) {
      submitForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preUpload]);

  useEffect(() => {
    dispatch(publishersSettingsActions.getPublisherGroups());
    dispatch(recommendedAsinsActions.getCategories({ publisher_id: filters?.publisher_id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Box sx={styles.fieldWrapper}>
        <Typography sx={styles.titleField}>Logo</Typography>
        <Box sx={styles.inputLogoWrapper}>
          <Box sx={styles.avatar} />
          <Box sx={styles.hiddenInput}>
            <input accept="image/*" type="file" ref={inputRef} onChange={handleChangeAvatar} />
          </Box>
          <Box sx={styles.logoButtons}>
            <Button color="primary" onClick={handleClickInput} sx={styles.button} variant="contained">
              Change
            </Button>
            <Button variant="outlined" color="error" sx={styles.button} onClick={handleDeleteAvatar}>
              Delete
            </Button>
          </Box>
        </Box>
      </Box>
      <Box sx={styles.fieldWrapper}>
        <Typography sx={styles.titleField}>Name</Typography>
        <Box sx={styles.inputWrapper}>
          <Field component={OutlinedInput} fullWidth name="name" sx={styles.field} />
        </Box>
      </Box>
      <Box sx={styles.fieldWrapper}>
        <Typography sx={styles.titleField}>Domain</Typography>
        <Box sx={styles.inputWrapper}>
          <Field component={OutlinedInput} fullWidth name="domain" sx={styles.field} />
        </Box>
      </Box>
      <Box sx={styles.fieldWrapper}>
        <Typography sx={styles.titleField}>Categories</Typography>
        <Box sx={styles.inputWrapper}>
          <Autocomplete
            sx={styles.field}
            multiple
            options={
              categories.data?.map(category =>
                category.parent_category.name === null ? 'Other' : category.parent_category.name
              ) || []
            }
            renderTags={(value, getTagProps) =>
              value.map((option, index) => <Chip variant="outlined" label={option} {...getTagProps({ index })} />)
            }
            renderInput={params => (
              <TextField {...params} variant="outlined" label="Categories" placeholder="Categories" />
            )}
            onChange={(event, value) => formik.setFieldValue('publisher_categories', value)}
            value={formik.values.publisher_categories}
            loading={categories.loading}
          />
        </Box>
      </Box>
      <Box sx={styles.fieldWrapper}>
        <Typography sx={styles.titleField}>Timezone</Typography>
        <Box sx={styles.inputWrapper}>
          <SelectField
            name="timezone"
            fullWidth
            InputLabelProps={{ shrink: true }}
            options={timezones}
            sx={styles.field}
          />
        </Box>
      </Box>
      {permissions && (
        <Box sx={styles.fieldWrapper}>
          <Box />
          {publisherGroups && publisher.publisher_group_ids?.length > 0 && (
            <Box sx={styles.groupsContainer}>
              {publisher.publisher_group_ids.map(group => (
                <Box sx={styles.groupContainer}>
                  <CheckCircleOutlineIcon fontSize="small" sx={styles.checkCircleIcon} />
                  {publisherGroups.publisher_groups.find(grp => grp.id === group).group_name}
                </Box>
              ))}
            </Box>
          )}
        </Box>
      )}
      <Box sx={styles.buttons}>
        <Button color="primary" onClick={submitForm} sx={styles.button} variant="contained">
          Save
        </Button>
      </Box>
    </Box>
  );
}
