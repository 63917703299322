import { Action } from 'common/interfaces/root';
import { asinRecommendationActionTypes as TYPES } from '../actions/action-types';

const initialState: AsinRecommendationModule.State.FiltersState = {
  parent_categories: [],
  categories: [],
  brand: [],
  type: 'all',
  status: 'all',
  seasons: 'none',
  in_stock: 'all',
  price_min: null,
  price_max: null,
  earn_min: null,
  earn_max: null,
  search: '',
  discount_products_only: false,
};

export default (
  state: AsinRecommendationModule.State.FiltersState = initialState,
  { type, payload }: Action
): AsinRecommendationModule.State.FiltersState => {
  switch (type) {
    case TYPES.FILTERS.SET_PARENT_CATEGORY:
      return { ...state, parent_categories: payload };
    case TYPES.FILTERS.SET_CATEGORY:
      return { ...state, categories: payload };
    case TYPES.FILTERS.SET_BRAND:
      return { ...state, brand: payload };
    case TYPES.FILTERS.SET_TYPE:
      return { ...state, type: payload };
    case TYPES.FILTERS.SET_STATUS:
      return { ...state, status: payload };
    case TYPES.FILTERS.SET_SEASONALITY:
      return { ...state, seasons: payload };
    case TYPES.FILTERS.SET_IN_STOCK:
      return { ...state, in_stock: payload };
    case TYPES.FILTERS.SET_PRICE_MIN:
      return { ...state, price_min: payload };
    case TYPES.FILTERS.SET_PRICE_MAX:
      return { ...state, price_max: payload };
    case TYPES.FILTERS.SET_EARN_MIN:
      return { ...state, earn_min: payload };
    case TYPES.FILTERS.SET_EARN_MAX:
      return { ...state, earn_max: payload };
    case TYPES.FILTERS.SET_SEARCH:
      return { ...state, search: payload };
    case TYPES.FILTERS.SET_DISCOUNT_MIN:
      return { ...state, discount_min: payload };
    case TYPES.FILTERS.SET_DISCOUNT_MAX:
      return { ...state, discount_max: payload };
    case TYPES.FILTERS.SET_RATING_MIN:
      return { ...state, rating_min: payload };
    case TYPES.FILTERS.SET_RATING_MAX:
      return { ...state, rating_max: payload };
    case TYPES.FILTERS.SET_DISCOUNT_PRODUCTS_ONLY:
      return { ...state, discount_products_only: payload };

    case TYPES.FILTERS.RESET_FILTERS:
      return initialState;

    default:
      return state;
  }
};
