import { SagaIterator } from '@redux-saga/types';
import { affiliateGainzSaga } from 'modules/affiliate-gainz/store/sagas';
import { asinFinderSaga } from 'modules/asin-finder/store/sagas';
import { attrSaga } from 'modules/attribution/store';
import { contentOverviewSaga } from 'modules/content-overview/store';
import { conversionsSaga } from 'modules/conversions/store/sagas';
import { overviewSaga } from 'modules/overview/store';
import { overviewSaga as overviewV2Saga } from 'modules/overview-v2/store';
import { publishersSettingsSaga } from 'modules/publishers-settings/store';
import { revenueByPageSaga } from 'modules/revenue-by-page/store';
import { affiliateRateCardSaga } from 'modules/settings/components/publishers-settings/publisher-settings/publisher-settings-form/conversion-settings/affiliate-rate-card/store/sagas';
import { settingsSaga } from 'modules/settings/store';
import { tagsSaga } from 'modules/tags/store';
import { urlsSaga } from 'modules/urls/store';
import { userSettingsSaga } from 'modules/user-settings/store';
import { mavPlusSaga } from 'modules/mav-plus/store';
import { all, fork, takeLatest } from 'redux-saga/effects';
import { asinRecommendationSaga } from 'modules/asin-recommendation/store/saga';
import { parentPublisherSettingsSaga } from 'modules/parent-publisher-settings/store';
import { publishersAffiliateTagsSaga } from 'modules/publishers-affiliate-tags/store/sagas';
import { asinsConversionsBoostSaga } from 'modules/asins-conversions-boost/store';
import { publisherBrandCommissionsSaga } from 'modules/publisher-brand-commissions-settings/store';
import { presetsActionsTypes } from '../actions/presets';
import { getBrandsSaga, getPresetsSaga, getTimeInfo } from './root';
import { amazonAffiliateReportsSaga } from '../../../modules/amazon-affiliate-reports/store/sagas';
import { brandsActionTypes } from '../actions/brands';

function* rootSaga(): SagaIterator {
  yield all([
    takeLatest(presetsActionsTypes.GET_FILTERS_PRESETS, getPresetsSaga),
    takeLatest(presetsActionsTypes.GET_TIME_INFO, getTimeInfo),
    takeLatest(brandsActionTypes.GET_BRANDS, getBrandsSaga),
    fork(overviewSaga),
    fork(revenueByPageSaga),
    fork(publishersSettingsSaga),
    fork(tagsSaga),
    fork(urlsSaga),
    fork(userSettingsSaga),
    fork(settingsSaga),
    fork(attrSaga),
    fork(contentOverviewSaga),
    fork(conversionsSaga),
    fork(asinFinderSaga),
    fork(affiliateGainzSaga),
    fork(affiliateRateCardSaga),
    fork(overviewV2Saga),
    fork(mavPlusSaga),
    fork(asinRecommendationSaga),
    fork(parentPublisherSettingsSaga),
    fork(amazonAffiliateReportsSaga),
    fork(publishersAffiliateTagsSaga),
    fork(asinsConversionsBoostSaga),
    fork(publisherBrandCommissionsSaga),
  ]);
}

export default rootSaga;
