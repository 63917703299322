import { Brand } from 'common/interfaces/brands';
import { brandsActionTypes as TYPES } from './action-types';

const getBrands = () => ({
  type: TYPES.GET_BRANDS,
});

const getBrandsSuccess = (payload: Array<Brand>) => ({
  type: TYPES.GET_BRANDS_SUCCESS,
  payload,
});

const getBrandsError = (error: Error) => ({
  type: TYPES.GET_BRANDS_ERROR,
  payload: error,
});

export const brandsActions = {
  getBrands,
  getBrandsSuccess,
  getBrandsError,
};
