const ASIN_RECOMMENDATION_MODULE = '[asin-recommendation]';

const GET_ASINS = `${ASIN_RECOMMENDATION_MODULE} GET_ASINS`;
const GET_ASINS_SUCCESS = `${ASIN_RECOMMENDATION_MODULE} GET_ASINS_SUCCESS`;
const GET_ASINS_ERROR = `${ASIN_RECOMMENDATION_MODULE} GET_ASINS_ERROR`;

const GET_FEATURED_ASINS = `${ASIN_RECOMMENDATION_MODULE} GET_FEATURED_ASINS`;
const GET_FEATURED_ASINS_SUCCESS = `${ASIN_RECOMMENDATION_MODULE} GET_FEATURED_ASINS_SUCCESS`;
const GET_FEATURED_ASINS_ERROR = `${ASIN_RECOMMENDATION_MODULE} GET_FEATURED_ASINS_ERROR`;

const GET_PLACEMENTS = `${ASIN_RECOMMENDATION_MODULE} GET_PLACEMENTS`;
const GET_PLACEMENTS_SUCCESS = `${ASIN_RECOMMENDATION_MODULE} GET_PLACEMENTS_SUCCESS`;
const GET_PLACEMENTS_ERROR = `${ASIN_RECOMMENDATION_MODULE} GET_PLACEMENTS_ERROR`;

const GENERATE_ATTR_TAG = `${ASIN_RECOMMENDATION_MODULE} GENERATE_ATTR_TAG`;
const GENERATE_ATTR_TAG_SUCCESS = `${ASIN_RECOMMENDATION_MODULE} GENERATE_ATTR_TAG_SUCCESS`;
const GENERATE_ATTR_TAG_ERROR = `${ASIN_RECOMMENDATION_MODULE} GENERATE_ATTR_TAG_ERROR`;

const GET_FILTERS = `${ASIN_RECOMMENDATION_MODULE} GET_FILTERS`;
const GET_FILTERS_SUCCESS = `${ASIN_RECOMMENDATION_MODULE} GET_FILTERS_SUCCESS`;
const GET_FILTERS_ERROR = `${ASIN_RECOMMENDATION_MODULE} GET_FILTERS_ERROR`;

const GET_EXPORT = `${ASIN_RECOMMENDATION_MODULE} GET_EXPORT`;
const GET_EXPORT_SUCCESS = `${ASIN_RECOMMENDATION_MODULE} GET_EXPORT_SUCCESS`;
const GET_EXPORT_ERROR = `${ASIN_RECOMMENDATION_MODULE} GET_EXPORT_ERROR`;

const GET_VARIATIONS = `${ASIN_RECOMMENDATION_MODULE} GET_VARIATIONS`;
const GET_VARIATIONS_SUCCESS = `${ASIN_RECOMMENDATION_MODULE} GET_VARIATIONS_SUCCESS`;
const GET_VARIATIONS_ERROR = `${ASIN_RECOMMENDATION_MODULE} GET_VARIATIONS_ERROR`;

const SET_CATEGORY = `${ASIN_RECOMMENDATION_MODULE} SET_CATEGORY`;
const SET_STATUS = `${ASIN_RECOMMENDATION_MODULE} SET_STATUS`;
const SET_BRAND = `${ASIN_RECOMMENDATION_MODULE} SET_BRAND`;
const SET_TYPE = `${ASIN_RECOMMENDATION_MODULE} SET_TYPE`;
const SET_SEASONALITY = `${ASIN_RECOMMENDATION_MODULE} SET_SEASONALITY`;
const SET_IN_STOCK = `${ASIN_RECOMMENDATION_MODULE} SET_IN_STOCK`;
const SET_PRICE_MAX = `${ASIN_RECOMMENDATION_MODULE} SET_PRICE_MAX`;
const SET_PRICE_MIN = `${ASIN_RECOMMENDATION_MODULE} SET_PRICE_MIN`;
const SET_EARN_MAX = `${ASIN_RECOMMENDATION_MODULE} SET_EARN_MAX`;
const SET_EARN_MIN = `${ASIN_RECOMMENDATION_MODULE} SET_EARN_MIN`;
const SET_EARN_SORT = `${ASIN_RECOMMENDATION_MODULE} SET_EARN_SORT`;
const SET_SEARCH = `${ASIN_RECOMMENDATION_MODULE} SET_SEARCH`;
const SET_PARENT_CATEGORY = `${ASIN_RECOMMENDATION_MODULE} SET_PARENT_CATEGORY`;
const SET_DISCOUNT_PRODUCTS_ONLY = `${ASIN_RECOMMENDATION_MODULE} SET_DISCOUNT_PRODUCTS_ONLY`;

const SET_LIMIT = `${ASIN_RECOMMENDATION_MODULE} SET_LIMIT`;
const SET_OFFSET = `${ASIN_RECOMMENDATION_MODULE} SET_OFFSET`;
const SET_PRODUCT_SORT = `${ASIN_RECOMMENDATION_MODULE} SET_PRODUCT_SORT`;
const SET_PRICE_SORT = `${ASIN_RECOMMENDATION_MODULE} SET_PRICE_SORT`;

const SET_GRID_LIMIT = `${ASIN_RECOMMENDATION_MODULE} SET_GRID_LIMIT`;
const SET_GRID_OFFSET = `${ASIN_RECOMMENDATION_MODULE} SET_GRID_OFFSET`;
const SET_GRID_FEATURED_LIMIT = `${ASIN_RECOMMENDATION_MODULE} SET_GRID_FEATURED_LIMIT`;
const SET_GRID_FEATURED_OFFSET = `${ASIN_RECOMMENDATION_MODULE} SET_GRID_FEATURED_OFFSET`;
const SET_GRID_VIEW_MORE = `${ASIN_RECOMMENDATION_MODULE} SET_GRID_VIEW_MORE`;
const SET_GRID_SELECTED = `${ASIN_RECOMMENDATION_MODULE} SET_GRID_SELECTED`;
const SET_GRID_MODAL_OPEN = `${ASIN_RECOMMENDATION_MODULE} SET_GRID_MODAL_OPEN`;

const RESET_FILTERS = `${ASIN_RECOMMENDATION_MODULE} RESET_FILTERS`;
const RESET_TABLE = `${ASIN_RECOMMENDATION_MODULE} RESET_TABLE`;

const SET_DISCOUNT_MIN = `${ASIN_RECOMMENDATION_MODULE} SET_DISCOUNT_MIN`;
const SET_DISCOUNT_MAX = `${ASIN_RECOMMENDATION_MODULE} SET_DISCOUNT_MAX`;

const SET_RATING_MIN = `${ASIN_RECOMMENDATION_MODULE} SET_RATING_MIN`;
const SET_RATING_MAX = `${ASIN_RECOMMENDATION_MODULE} SET_RATING_MAX`;

export const asinRecommendationActionTypes = {
  ASINS: {
    GET_ASINS,
    GET_ASINS_SUCCESS,
    GET_ASINS_ERROR,
    GET_FEATURED_ASINS,
    GET_FEATURED_ASINS_SUCCESS,
    GET_FEATURED_ASINS_ERROR,
  },
  PLACEMENTS: {
    GET_PLACEMENTS,
    GET_PLACEMENTS_SUCCESS,
    GET_PLACEMENTS_ERROR,
  },
  INITIAL_FILTERS: {
    GET_FILTERS,
    GET_FILTERS_SUCCESS,
    GET_FILTERS_ERROR,
  },
  ATTR_TAG: {
    GENERATE_ATTR_TAG,
    GENERATE_ATTR_TAG_SUCCESS,
    GENERATE_ATTR_TAG_ERROR,
  },
  EXPORT: {
    GET_EXPORT,
    GET_EXPORT_SUCCESS,
    GET_EXPORT_ERROR,
  },
  VARIATIONS: {
    GET_VARIATIONS,
    GET_VARIATIONS_SUCCESS,
    GET_VARIATIONS_ERROR,
  },
  FILTERS: {
    SET_CATEGORY,
    SET_BRAND,
    SET_TYPE,
    SET_STATUS,
    SET_SEASONALITY,
    SET_IN_STOCK,
    SET_PRICE_MAX,
    SET_PRICE_MIN,
    SET_EARN_MAX,
    SET_EARN_MIN,
    SET_EARN_SORT,
    SET_SEARCH,
    RESET_FILTERS,
    SET_PARENT_CATEGORY,
    SET_DISCOUNT_MAX,
    SET_DISCOUNT_MIN,
    SET_RATING_MAX,
    SET_RATING_MIN,
    SET_DISCOUNT_PRODUCTS_ONLY,
  },
  TABLE: {
    SET_LIMIT,
    SET_OFFSET,
    SET_PRODUCT_SORT,
    SET_PRICE_SORT,
    RESET_TABLE,
  },
  GRID: {
    SET_GRID_LIMIT,
    SET_GRID_OFFSET,
    SET_GRID_FEATURED_LIMIT,
    SET_GRID_FEATURED_OFFSET,
    SET_GRID_VIEW_MORE,
    SET_GRID_SELECTED,
    SET_GRID_MODAL_OPEN,
  },
};
