import { asinRecommendationActionTypes as TYPES } from './action-types';

const getAsins = (params: AsinRecommendationModule.Params.GetAsinsParams) => ({
  type: TYPES.ASINS.GET_ASINS,
  payload: params,
});

const getAsinsSuccess = (data: AsinRecommendationModule.State.RecommendedAsinsData) => ({
  type: TYPES.ASINS.GET_ASINS_SUCCESS,
  payload: data,
});

const getAsinsError = (error: Error) => ({
  type: TYPES.ASINS.GET_ASINS_ERROR,
  payload: error,
});

const getFeaturedAsins = (params: AsinRecommendationModule.Params.GetAsinsParams) => ({
  type: TYPES.ASINS.GET_FEATURED_ASINS,
  payload: params,
});

const getFeaturedAsinsSuccess = (data: AsinRecommendationModule.State.RecommendedAsinsData) => ({
  type: TYPES.ASINS.GET_FEATURED_ASINS_SUCCESS,
  payload: data,
});

const getFeaturedAsinsError = (error: Error) => ({
  type: TYPES.ASINS.GET_FEATURED_ASINS_ERROR,
  payload: error,
});

const getInitialFilters = (payload: { publisher_id: number }) => ({
  type: TYPES.INITIAL_FILTERS.GET_FILTERS,
  payload,
});

const getInitialFiltersSuccess = payload => ({
  type: TYPES.INITIAL_FILTERS.GET_FILTERS_SUCCESS,
  payload,
});

const getInitialFiltersError = (error: Error) => ({
  type: TYPES.INITIAL_FILTERS.GET_FILTERS_ERROR,
  payload: error,
});

const getPlacements = (params: AsinRecommendationModule.Params.GetPlacementParams) => ({
  type: TYPES.PLACEMENTS.GET_PLACEMENTS,
  payload: params,
});

const getPlacementsSuccess = data => ({
  type: TYPES.PLACEMENTS.GET_PLACEMENTS_SUCCESS,
  payload: data,
});

const getPlacementsError = (error: Error) => ({
  type: TYPES.PLACEMENTS.GET_PLACEMENTS_ERROR,
  payload: error,
});

const getExport = (params: AsinRecommendationModule.Params.GetAsinsParams) => ({
  type: TYPES.EXPORT.GET_EXPORT,
  payload: params,
});

const getExportSuccess = (data: AsinRecommendationModule.State.ExportState | null) => ({
  type: TYPES.EXPORT.GET_EXPORT_SUCCESS,
  payload: data,
});

const getExportError = (error: Error) => ({
  type: TYPES.EXPORT.GET_EXPORT_ERROR,
  payload: error,
});

const getVariations = (params: AsinRecommendationModule.Params.GetVariationsParams) => ({
  type: TYPES.VARIATIONS.GET_VARIATIONS,
  payload: params,
});

const getVariationsSuccess = (data: AsinRecommendationModule.State.VariationsState) => ({
  type: TYPES.VARIATIONS.GET_VARIATIONS_SUCCESS,
  payload: data,
});

const getVariationsError = (error: Error) => ({
  type: TYPES.VARIATIONS.GET_VARIATIONS_ERROR,
  payload: error,
});

const setCategory = (payload: string[]) => ({
  type: TYPES.FILTERS.SET_CATEGORY,
  payload,
});

const setBrand = (brand: string[]) => ({
  type: TYPES.FILTERS.SET_BRAND,
  payload: brand,
});

const setType = (type: string) => ({
  type: TYPES.FILTERS.SET_TYPE,
  payload: type,
});

const setStatus = (status: string) => ({
  type: TYPES.FILTERS.SET_STATUS,
  payload: status,
});

const setSeasonality = (seasons: string) => ({
  type: TYPES.FILTERS.SET_SEASONALITY,
  payload: seasons,
});

const setInStock = (inStock: string) => ({
  type: TYPES.FILTERS.SET_IN_STOCK,
  payload: inStock,
});

const setPriceMin = (priceMin: string) => ({
  type: TYPES.FILTERS.SET_PRICE_MIN,
  payload: priceMin,
});

const setPriceMax = (priceMax: string) => ({
  type: TYPES.FILTERS.SET_PRICE_MAX,
  payload: priceMax,
});

const setEarnMin = (earnMin: string) => ({
  type: TYPES.FILTERS.SET_EARN_MIN,
  payload: earnMin,
});

const setEarnMax = (earnMax: string) => ({
  type: TYPES.FILTERS.SET_EARN_MAX,
  payload: earnMax,
});

const setSearch = (search: string) => ({
  type: TYPES.FILTERS.SET_SEARCH,
  payload: search,
});

const resetFilters = () => ({
  type: TYPES.FILTERS.RESET_FILTERS,
});

const setLimit = (limit: number) => ({
  type: TYPES.TABLE.SET_LIMIT,
  payload: limit,
});

const setOffset = (offset: number) => ({
  type: TYPES.TABLE.SET_OFFSET,
  payload: offset,
});

const setProductSort = (productSort: string) => ({
  type: TYPES.TABLE.SET_PRODUCT_SORT,
  payload: productSort,
});

const setPriceSort = (priceSort: string) => ({
  type: TYPES.TABLE.SET_PRICE_SORT,
  payload: priceSort,
});

const resetTable = () => ({
  type: TYPES.TABLE.RESET_TABLE,
});

const setFeaturedLimit = (limit: number) => ({
  type: TYPES.GRID.SET_GRID_FEATURED_LIMIT,
  payload: limit,
});

const setFeaturedOffset = (offset: number) => ({
  type: TYPES.GRID.SET_GRID_FEATURED_OFFSET,
  payload: offset,
});

const setGridLimit = (limit: number) => ({
  type: TYPES.GRID.SET_GRID_LIMIT,
  payload: limit,
});

const setGridOffset = (offset: number) => ({
  type: TYPES.GRID.SET_GRID_OFFSET,
  payload: offset,
});

const setGridModalOpen = (open: boolean) => ({
  type: TYPES.GRID.SET_GRID_MODAL_OPEN,
  payload: open,
});

const setViewMore = (viewMore: boolean) => ({
  type: TYPES.GRID.SET_GRID_VIEW_MORE,
  payload: viewMore,
});

const setSelected = (selected: AsinRecommendationModule.State.Asin) => ({
  type: TYPES.GRID.SET_GRID_SELECTED,
  payload: selected,
});

const generateAttrTag = (publisherId: number, asin: string, url: string, onSuccess?: (value: string) => void) => ({
  type: TYPES.ATTR_TAG.GENERATE_ATTR_TAG,
  payload: { publisherId, asin, url },
  meta: { onSuccess },
});

const generateAttrTagSuccess = () => ({
  type: TYPES.ATTR_TAG.GENERATE_ATTR_TAG_SUCCESS,
});

const generateAttrTagError = () => ({
  type: TYPES.ATTR_TAG.GENERATE_ATTR_TAG_ERROR,
});

const setParentCategory = (payload: string[]) => ({
  type: TYPES.FILTERS.SET_PARENT_CATEGORY,
  payload,
});

const setDiscountMin = (payload: number) => ({
  type: TYPES.FILTERS.SET_DISCOUNT_MIN,
  payload,
});

const setDiscountMax = (payload: number) => ({
  type: TYPES.FILTERS.SET_DISCOUNT_MAX,
  payload,
});

const setRatingMin = (payload: number) => ({
  type: TYPES.FILTERS.SET_RATING_MIN,
  payload,
});

const setRatingMax = (payload: number) => ({
  type: TYPES.FILTERS.SET_RATING_MAX,
  payload,
});

const setDiscountProductsOnly = (payload: boolean) => ({
  type: TYPES.FILTERS.SET_DISCOUNT_PRODUCTS_ONLY,
  payload,
});

export const asinRecommendationActions = {
  asins: {
    getAsins,
    getAsinsSuccess,
    getAsinsError,
  },
  featuredAsins: {
    getFeaturedAsins,
    getFeaturedAsinsSuccess,
    getFeaturedAsinsError,
  },
  placements: {
    getPlacements,
    getPlacementsSuccess,
    getPlacementsError,
  },
  initialFilters: {
    getInitialFilters,
    getInitialFiltersSuccess,
    getInitialFiltersError,
  },
  export: {
    getExport,
    getExportSuccess,
    getExportError,
  },
  filters: {
    setParentCategory,
    setCategory,
    setBrand,
    setType,
    setStatus,
    setSeasonality,
    setInStock,
    setPriceMin,
    setPriceMax,
    setEarnMin,
    setEarnMax,
    setSearch,
    resetFilters,
    setDiscountMin,
    setDiscountMax,
    setRatingMin,
    setRatingMax,
    setDiscountProductsOnly,
  },
  table: {
    setLimit,
    setOffset,
    setProductSort,
    setPriceSort,
    resetTable,
  },
  grid: {
    setGridLimit,
    setGridOffset,
    setFeaturedLimit,
    setFeaturedOffset,
    setViewMore,
    setSelected,
    setGridModalOpen,
  },
  variations: {
    getVariations,
    getVariationsSuccess,
    getVariationsError,
  },
  attrTag: {
    generateAttrTag,
    generateAttrTagSuccess,
    generateAttrTagError,
  },
};
