import { ReactElement } from 'react';
import { Table, TableHead, TableRow, TableCell, TableBody, Tooltip, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { Styles } from 'common/types';

interface BrandsCommissionsTableProps {
  loading: boolean;
  data?: PublishersBrandCommissionsModule.PublisherBrandCommissionData[];
  onDeleteIconClick: (brand_id: number) => void;
  onEditIconClick: ({ brand_id, commission_rate }: { brand_id: number; commission_rate: number }) => void;
}

const styles: Styles = {
  actions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export function BrandsCommissionsTable({
  loading,
  data,
  onEditIconClick,
  onDeleteIconClick,
}: BrandsCommissionsTableProps): ReactElement {
  const percentsFormatter = new Intl.NumberFormat('en', {
    style: 'percent',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  const renderRow = (data: PublishersBrandCommissionsModule.PublisherBrandCommissionData) => {
    return (
      <TableRow>
        <TableCell>{data?.brand_name || '-'}</TableCell>
        <TableCell>{percentsFormatter.format(Number(data?.commission_rate)) || '-'}</TableCell>
        <TableCell>
          <Box sx={styles.actions}>
            <Tooltip title="Edit">
              <EditIcon
                onClick={() =>
                  onEditIconClick({
                    brand_id: data?.brand_id,
                    commission_rate: Number(data?.commission_rate),
                  })
                }
              />
            </Tooltip>
            <Tooltip title="Delete">
              <DeleteIcon onClick={() => onDeleteIconClick(data?.brand_id)} />
            </Tooltip>
          </Box>
        </TableCell>
      </TableRow>
    );
  };
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Brand</TableCell>
          <TableCell>Commission rate</TableCell>
          <TableCell align="center">Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{!loading && data?.map(item => renderRow(item))}</TableBody>
    </Table>
  );
}
