import { conversionsBoostActions } from './conversionsBoost';
import { exportActions } from './export';
import { filtersActions } from './filters';
import { uploadActions } from './upload';

export const asinsConversionsBoostActions = {
  conversionsBoostActions,
  uploadActions,
  exportActions,
  filtersActions,
};
