import { combineReducers } from 'redux';
import asins from './asins';
import filters from './filters';
import exports from './export';
import placements from './placements';
import featuredAsins from './featured-asins';
import table from './table';
import grid from './grid';
import variations from './variations';
import initialFilters from './initial-filters';

export const asinRecommendationReducer = combineReducers({
  filters,
  asins,
  exports,
  placements,
  featuredAsins,
  table,
  grid,
  variations,
  initialFilters,
});
