import { State } from 'common/store/reducers';

const selectConversionsBoost = (state: State) => state.asinsConversionsBoost.conversionsBoost;
const selectExport = (state: State) => state.asinsConversionsBoost.exports;
const selectUpload = (state: State) => state.asinsConversionsBoost.upload;
const selectFilters = (state: State) => state.asinsConversionsBoost.filters;

export const asinsConversionsBoostSelectors = {
  selectConversionsBoost,
  selectExport,
  selectUpload,
  selectFilters,
};
