const BRANDS_MODULE = '[brands]';

const GET_BRANDS = `${BRANDS_MODULE} GET_BRANDS`;
const GET_BRANDS_SUCCESS = `${BRANDS_MODULE} GET_BRANDS_SUCCESS`;
const GET_BRANDS_ERROR = `${BRANDS_MODULE} GET_BRANDS_ERROR`;

export const brandsActionTypes = {
  GET_BRANDS,
  GET_BRANDS_SUCCESS,
  GET_BRANDS_ERROR,
};
