import { SagaIterator } from '@redux-saga/types';
import { Action } from 'common/interfaces/root';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { affiliateGainzService } from '../../service/affiliate-gainz-service';
import { recommendedAsinsActions, recommendedAsinsActionTypes } from '../recommended-asins/actions';

function* getAsinsSaga({ payload }: Action) {
  try {
    const { data } = yield call(affiliateGainzService.getAsins, payload);

    yield put(recommendedAsinsActions.getAsinsSuccess(data));
  } catch (error) {
    yield put(recommendedAsinsActions.getAsinsError(error as Error));
  }
}

function* getFeaturedAsinsSaga({ payload }: Action) {
  try {
    const { data } = yield call(affiliateGainzService.getAsins, payload);

    yield put(recommendedAsinsActions.getFeaturedAsinsSuccess(data));
  } catch (error) {
    yield put(recommendedAsinsActions.getFeaturedAsinsError(error as Error));
  }
}

function* getPlacementsSaga({ payload }: Action) {
  try {
    const { data } = yield call(affiliateGainzService.getPlacements, payload);

    yield put(recommendedAsinsActions.getPlacementsSuccess(data));
  } catch (error) {
    yield put(recommendedAsinsActions.getPlacementsError(error as Error));
  }
}

function* getRecommendedAsinsExportSaga({ payload }: Action) {
  try {
    const { data } = yield call(affiliateGainzService.getRecommendedAsinsExport, payload);

    yield put(recommendedAsinsActions.getExportSuccess(data));
  } catch (error) {
    yield put(recommendedAsinsActions.getExportError(error as Error));
  }
}

function* getCategoriesSaga({ payload }: Action) {
  try {
    const { data } = yield call(affiliateGainzService.getCategories, payload);

    yield put(recommendedAsinsActions.getCategoriesSuccess(data.categories));
  } catch (error) {
    yield put(recommendedAsinsActions.getCategoriesError(error as Error));
  }
}

export function* affiliateGainzSaga(): SagaIterator {
  yield all([
    takeLatest(recommendedAsinsActionTypes.ASINS.GET_ASINS, getAsinsSaga),
    takeLatest(recommendedAsinsActionTypes.ASINS.GET_FEATURED_ASINS, getFeaturedAsinsSaga),
    takeLatest(recommendedAsinsActionTypes.PLACEMENTS.GET_PLACEMENTS, getPlacementsSaga),
    takeLatest(recommendedAsinsActionTypes.EXPORT.GET_EXPORT, getRecommendedAsinsExportSaga),
    takeLatest(recommendedAsinsActionTypes.CATEGORIES.GET_CATEGORIES, getCategoriesSaga),
  ]);
}
