import { State } from './reducers';

export const selectRootLanguage = (state: State): string => state.root.lang;

export const selectRootLoading = (state: State): boolean => state.root.loading;

export const selectRootDemo = (state: State): boolean => state.root.demo;

export const selectGlobalFilters = (state: State) => state.filters;

export const selectFiltersPresets = (state: State) => state.presets.filters;

export const selectPresetsLoading = (state: State) => state.presets.loading;

export const selectAccess = (state: State) => state.accessReducer;

export const selectDatePickerComparator = (state: State) => state.datePickerComparatorReducer;

export const selectBrands = (state: State) => state.brands.data;

export const selectBrandsLoading = (state: State): boolean => state.brands.loading;
