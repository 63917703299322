import { recommendedAsinsActionTypes as TYPES } from './action-types';

const getAsins = (payload: AffiliateGainzModule.RecommendedAsinsModule.FiltersState) => ({
  type: TYPES.ASINS.GET_ASINS,
  payload,
});

const getAsinsSuccess = (payload: AffiliateGainzModule.RecommendedAsinsModule.RecommendedAsinsData) => ({
  type: TYPES.ASINS.GET_ASINS_SUCCESS,
  payload,
});

const getAsinsError = (payload: Error) => ({
  type: TYPES.ASINS.GET_ASINS_ERROR,
  payload,
});

const getNewAsins = (payload: AffiliateGainzModule.RecommendedAsinsModule.FiltersState) => ({
  type: TYPES.ASINS.GET_NEW_ASINS,
  payload,
});

const getNewAsinsSuccess = (payload: AffiliateGainzModule.RecommendedAsinsModule.RecommendedAsinsData) => ({
  type: TYPES.ASINS.GET_NEW_ASINS_SUCCESS,
  payload,
});

const getNewAsinsError = (payload: Error) => ({
  type: TYPES.ASINS.GET_NEW_ASINS_ERROR,
  payload,
});

const getFeaturedAsins = (payload: AffiliateGainzModule.RecommendedAsinsModule.FiltersState) => ({
  type: TYPES.ASINS.GET_FEATURED_ASINS,
  payload,
});

const getFeaturedAsinsSuccess = (payload: AffiliateGainzModule.RecommendedAsinsModule.RecommendedAsinsData) => ({
  type: TYPES.ASINS.GET_FEATURED_ASINS_SUCCESS,
  payload,
});

const getFeaturedAsinsError = (payload: Error) => ({
  type: TYPES.ASINS.GET_FEATURED_ASINS_ERROR,
  payload,
});

const getPlacements = (payload: AffiliateGainzModule.RecommendedAsinsModule.PlacementParams) => ({
  type: TYPES.PLACEMENTS.GET_PLACEMENTS,
  payload,
});

const getPlacementsSuccess = (payload: string[]) => ({
  type: TYPES.PLACEMENTS.GET_PLACEMENTS_SUCCESS,
  payload,
});

const getPlacementsError = (payload: Error) => ({
  type: TYPES.PLACEMENTS.GET_PLACEMENTS_ERROR,
  payload,
});

const getCategories = (payload: { publisher_id: number }) => ({
  type: TYPES.CATEGORIES.GET_CATEGORIES,
  payload,
});

const getCategoriesSuccess = (payload: AffiliateGainzModule.RecommendedAsinsModule.Category[]) => ({
  type: TYPES.CATEGORIES.GET_CATEGORIES_SUCCESS,
  payload,
});

const getCategoriesError = (payload: Error) => ({
  type: TYPES.CATEGORIES.GET_CATEGORIES_ERROR,
  payload,
});

const getExport = (payload: AffiliateGainzModule.RecommendedAsinsModule.FiltersState) => ({
  type: TYPES.EXPORT.GET_EXPORT,
  payload,
});

const getExportSuccess = (payload: string | null) => ({
  type: TYPES.EXPORT.GET_EXPORT_SECCUESS,
  payload,
});

const getExportError = (payload: Error) => ({
  type: TYPES.EXPORT.GET_EXPORT_ERROR,
  payload,
});

const setSearch = (payload: string) => ({
  type: TYPES.FILTERS.SET_SEARCH,
  payload,
});

const setCategory = (payload: string[]) => ({
  type: TYPES.FILTERS.SET_CATEGORY,
  payload,
});

const setLimit = (payload: number) => ({
  type: TYPES.FILTERS.SET_LIMIT,
  payload,
});

const setOffset = (payload: number) => ({
  type: TYPES.FILTERS.SET_OFFSET,
  payload,
});

const setStatus = (payload: string) => ({
  type: TYPES.FILTERS.SET_STATUS,
  payload,
});

const setSeason = (payload: string) => ({
  type: TYPES.FILTERS.SET_SEASON,
  payload,
});

const setPriceMin = (payload: string) => ({
  type: TYPES.FILTERS.SET_PRICE_MIN,
  payload,
});

const setPriceMax = (payload: string) => ({
  type: TYPES.FILTERS.SET_PRICE_MAX,
  payload,
});

const setEarnMin = (payload: string) => ({
  type: TYPES.FILTERS.SET_EARN_MIN,
  payload,
});

const setEarnMax = (payload: string) => ({
  type: TYPES.FILTERS.SET_EARN_MAX,
  payload,
});

const setProductSort = (payload: 'asc' | 'desc') => ({
  type: TYPES.FILTERS.SET_PRODUCT_SORT,
  payload,
});

const setPriceSort = (payload: 'asc' | 'desc') => ({
  type: TYPES.FILTERS.SET_PRICE_SORT,
  payload,
});

const setEarnSort = (payload: 'asc' | 'desc') => ({
  type: TYPES.FILTERS.SET_EARN_SORT,
  payload,
});

const resetFilters = () => ({
  type: TYPES.FILTERS.RESET_FILTERS,
});

export const recommendedAsinsActions = {
  getAsins,
  getAsinsSuccess,
  getAsinsError,
  getNewAsins,
  getNewAsinsSuccess,
  getNewAsinsError,
  getFeaturedAsins,
  getFeaturedAsinsSuccess,
  getFeaturedAsinsError,
  getPlacements,
  getPlacementsSuccess,
  getPlacementsError,
  getCategories,
  getCategoriesSuccess,
  getCategoriesError,
  setSearch,
  setStatus,
  setSeason,
  setCategory,
  setLimit,
  setOffset,
  setPriceMin,
  setPriceMax,
  setEarnMin,
  setEarnMax,
  setProductSort,
  setPriceSort,
  setEarnSort,
  getExport,
  getExportSuccess,
  getExportError,
  resetFilters,
};
